import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/order_sticker';

export default {
    getOrderStickerByIdClientAndIdProfessional(): Promise<AxiosResponse<IServerResponse<IOrderSticker[]>>> {
        return axios.get(`${resource}`);
    },

    postOrderSticker(orderSticker): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}`, orderSticker);
    },
}