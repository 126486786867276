<template>
    <div id="datatable-filter-component">
        <div class="mb-7">
            <v-row class="mx-0 filter-white-background justify-center ">
                <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                              class="white-text-field append-inner-center-icon w-80"
                              dense filled hide-details
                              :label="$t('datatable_filter_component.research')"
                              @click="dialog_filter = true"
                >
                    <!--                    <template v-slot:append>
                                            <v-icon class="" large>mdi-tune</v-icon>
                                        </template>-->
                </v-text-field>

                <v-icon :color="dialog_sort ? 'primary' : ''" class="ml-2 my-auto" large @click="dialog_sort = true">
                    mdi-cog-outline
                </v-icon>
            </v-row>

            <v-expand-transition>
                <v-sheet v-if="dialog_filter" v-click-outside="closeFilterDialog" class="filter-card mt-4"
                         elevation="1"
                >
                    <v-card-title class="pb-0">
                        <p class="mb-0 font-weight-bold font-16">
                            {{ $t('datatable_filter_component.advanced_research') }}
                        </p>
                    </v-card-title>

                    <v-card-text>
                        <v-list>
                            <v-row v-for="(filter, index) in headers_copy" :key="index" class="ml-0 height-row">
                                <p class="my-auto">{{ filter.text }}</p>
                                <v-spacer/>
                                <v-switch v-model="filter.filterable" color="primary" inset/>
                            </v-row>
                        </v-list>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="closeFilterDialog">
                            <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>
                        <v-btn color="secondary" text @click="sortAndFilter(true)">
                            <span class="secondary--text font-weight-bold">{{ $t('global.apply') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-sheet>
            </v-expand-transition>
        </div>

        <v-dialog v-model="dialog_sort" max-width="500px">
            <v-card>
                <v-card-title>{{ $t('datatable_filter_component.configure_array') }}</v-card-title>

                <v-card-text>
                    <p class="font-weight-bold">{{ $t('datatable_filter_component.column_display') }}</p>
                    <v-list>
                        <v-row v-for="(colonne, index) in headers_copy" :key="index" class="pa-0 ml-0 height-row">
                            <p class="my-auto">{{ colonne.text }}</p>
                            <v-spacer/>
                            <v-icon :color="colonne.visible ? 'primary' : 'disabled'"
                                    @click="colonne.visible = !colonne.visible"
                                    class="px-1"
                            >
                                {{ colonne.visible ? 'mdi-eye' : 'mdi-eye-off' }}
                            </v-icon>
                        </v-row>
                    </v-list>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn class="font-weight-bold" text @click="closeSortDialog()">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn color="secondary" text @click="sortAndFilter(false)">
                        <span class="secondary--text font-weight-bold">{{ $t('global.apply') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'DatatableFilterComponent',
    props: {
        headers: {
            type: Array,
            required: true
        }
    },
    watch: {
        search: {
            handler() {
                this.dialog_filter = true;
                this.$parent.search = this.search;
            },
            deep: true,
            immediate: false
        }
    },
    data() {
        return {
            dialog_sort: false,
            headers_copy: [
                {text: this.$t('datatable_filter_component.autocompletion'), value: 'Autocompletion', visible: false, filterable: true}
            ],
            local_headers: [],

            search: '',
            dialog_filter: false
        }
    },
    methods: {
        sortAndFilter(filter) {
            this.local_headers = [];
            this.headers_copy.forEach(header_copy => {
                this.local_headers.push({
                    text: header_copy.text,
                    value: header_copy.value,
                    filterable: header_copy.filterable
                });
            })
            this.headers_copy.forEach((header_copy, index_copy) => {
                if (!header_copy.visible) {
                    this.headers_copy[index_copy].filterable = false;

                    this.local_headers.forEach((header, index) => {
                        if (header.text === header_copy.text) {
                            this.local_headers.splice(index, 1);
                        }
                    })
                }
            })
            this.$parent.headers = this.local_headers;

            if (filter) {
                //trigger search watch function
                this.search = this.search + "a";
                this.search = this.search.substring(0, this.search.length - 1);
            }

            //delay to close
            setTimeout(() => {
                this.dialog_sort = false;
                this.dialog_filter = false;
            }, 10)
        },

        closeSortDialog() {
            this.headers_copy.forEach((header_copy, index) => {
                let visible = false;
                this.local_headers.forEach((header) => {
                    if (header.text === header_copy.text) {
                        visible = true;
                    }
                })
                this.headers_copy[index].visible = visible;
            })
            this.dialog_sort = false;
        },

        closeFilterDialog() {
            this.headers_copy.forEach(filter => {
                filter.filterable = false;
                this.headers.forEach(header => {
                    if (header.filterable) {
                        if (filter.text === header.text) {
                            filter.filterable = true;
                        }
                    }
                })
            })
            this.dialog_filter = false;
        }
    },
    mounted() {
        this.headers_copy = [];
        this.headers.forEach(header => {
            this.headers_copy.push({text: header.text, value: header.value, visible: true, filterable: true});
        })
        this.local_headers = this.headers;
    },
}
</script>

<style>
.filter-card {
    z-index: 5;
    position: absolute;
    left: 16px;
    width: 270px;
    right: 15px;
}

.height-row {
    height: 35px;
}

.append-inner-center-icon .v-input__append-inner {
    margin-top: 12px !important;
}
</style>