<template>
    <div id="asking-stickers-page">
        <toolbar-component :title="$t('stickers_page.asking_stickers')"/>

        <div class="mb-16 pb-16 pa-4">
            <v-expand-transition>
                <v-alert v-if="show_alert" type="success" dismissible close-icon="mdi-close" text>
                    <p class="success--text font-12 line-height-16 mb-0">
                        {{ $t('stickers_page.orders_request_sent') }}
                    </p>
                </v-alert>
            </v-expand-transition>
            <div class="font-14 mb-6">
                <p class="font-color-medium mb-2">
                    {{ $t('stickers_page.dress_up_vehicles_with_stickers') }}
                </p>
                <p class="font-color-medium">
                    {{ $t('stickers_page.order_sticker_studied') }}
                </p>
            </div>

            <datatable-filter-component :headers="headers"/>

            <v-row class="mb-2 mx-0 justify-space-between">
                <p class="font-weight-bold">
                    {{ $t('stickers_page.total_demand_stickers', {total: order_stickers.length}) }}
                </p>
                <v-btn class="square-btn-icon" color="secondary" outlined @click="downloadCsvStickers()">
                    <v-icon color="secondary">mdi-download</v-icon>
                </v-btn>
            </v-row>

            <v-data-table
                :loading="loading_sticker"
                :headers="headers"
                :items="order_stickers"
                :search="search"
                disable-sort
                class="stripe-date-table"
            >
                <template v-slot:item.date_order="{ item }">
                    <span>{{ moment(item.date_order).format(date_format) }}</span>
                </template>

                <template v-for="referential_sticker in referential_stickers"
                          v-slot:[`item.referentiel_sticker_${referential_sticker.id_referentiel_sticker}`]="{ item }"
                >
                    <span :key="referential_sticker.id_referentiel_sticker">
                        <template v-for="order_referential_sticker in item.order_referentiel_stickers">
                           <template
                               v-if="order_referential_sticker.id_referentiel_sticker === referential_sticker.id_referentiel_sticker"
                           >
                                <span :key="order_referential_sticker.id_order_referentiel_sticker">
                                    {{ order_referential_sticker.total_ordered }}
                                </span>
                           </template>
                        </template>
                    </span>
                </template>
            </v-data-table>
        </div>

        <v-btn v-if="userHasAccess('PWA_STICKERS', role_edit)"
               fab color="secondary" elevation="0"
               :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'"
               @click="navigateTo('asking_stickers')"
        >
            <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
    </div>
</template>

<script>
import ToolbarComponent from "../../components/Global/ToolbarComponent";
import DatatableFilterComponent from "@/components/Global/DatatableFilterComponent";
import ReferentielStickerRepository from "@/repositories/ReferentielStickerRepository";
import OrderStickerRepository from "@/repositories/OrderStickerRepository";

export default {
    name: 'AskingStickersPage',
    components: {DatatableFilterComponent, ToolbarComponent},
    data() {
        return {
            show_alert: false,
            loading_sticker: true,
            search: '',
            headers: [
                {text: this.$t('stickers_page.date_demand'), value: 'date_order', filterable: true},
                {text: this.$t('stickers_page.vehicle_concerned'), value: 'total_vehicle_concerned', filterable: true},
                {text: this.$t('stickers_page.reason'), value: 'receiver_reason', filterable: true},
                {text: this.$t('stickers_page.full_name'), value: 'receiver_full_name', filterable: true},
                {text: this.$t('stickers_page.email'), value: 'receiver_email', filterable: true},
                {text: this.$t('stickers_page.address_postal'), value: 'address_postal', filterable: true},
                {text: this.$t('stickers_page.address_complement'), value: 'address_complement', filterable: true},
                {text: this.$t('stickers_page.postal_code'), value: 'code_postal', filterable: true},
                {text: this.$t('stickers_page.city'), value: 'city', filterable: true},
                {text: this.$t('stickers_page.country'), value: 'country', filterable: true}
            ],
            order_stickers: [],
            referential_stickers: []
        }
    },
    created() {
        this.show_alert = this.$route.query.show_alert;
        this.getOrderStickerByIdClientAndIdProfessional();
        this.getReferentialStickerByIdProfessional();
    },
    methods: {
        getOrderStickerByIdClientAndIdProfessional() {
            OrderStickerRepository.getOrderStickerByIdClientAndIdProfessional().then((success) => {
                this.order_stickers = success.data.data;
            }).catch((error) => {
                this.manageError(error);
            });
        },

        getReferentialStickerByIdProfessional() {
            ReferentielStickerRepository.getReferentielStickerByIdProfessional().then((success) => {
                this.referential_stickers = success.data.data;
                this.updateHeadersDatatableEngin();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_sticker = false;
            });
        },

        updateHeadersDatatableEngin() {
            let indexHeaders = 2;
            this.referential_stickers.forEach((referential_sticker) => {
                this.headers.splice(indexHeaders, 0, {
                    text: (referential_sticker.category === 'sticker' ? 'Sticker ' : '') + referential_sticker.label,
                    value: 'referentiel_sticker_' + referential_sticker.id_referentiel_sticker
                });
                indexHeaders++;
            });
        },

        downloadCsvStickers() {
            let csvData = [];
            csvData.push([
                this.$t('stickers_page.date_demand'), this.$t('stickers_page.vehicle_concerned'), this.$t('stickers_page.reason'),
                this.$t('stickers_page.full_name'), this.$t('stickers_page.email'), this.$t('stickers_page.address_postal'),
                this.$t('stickers_page.address_complement'), this.$t('stickers_page.postal_code'), this.$t('stickers_page.city'),
                this.$t('stickers_page.country')
            ]);

            this.referential_stickers.forEach((referential_sticker) => {
                csvData[0].push('Sticker ' + referential_sticker.label)
            });

            let index = 1;
            this.order_stickers.forEach((order_sticker) => {
                csvData.push([
                    (order_sticker.date_order ? this.moment(order_sticker.date_order).format(this.date_format_csv) : ''),
                    order_sticker.total_vehicle_concerned, order_sticker.receiver_reason.replace(/,/g, ''),
                    order_sticker.receiver_full_name.replace(/,/g, ''), order_sticker.receiver_email,
                    order_sticker.address_postal.replace(/,/g, ''), order_sticker.address_complement.replace(/,/g, ''),
                    order_sticker.code_postal, order_sticker.city, order_sticker.country,
                ]);

                order_sticker.order_referentiel_stickers.forEach((orderReferentialSticker) => {
                    csvData[index].push(orderReferentialSticker.total_ordered);
                });
                index++;
            });

            this.downloadCsvFile(csvData, 'stickers');
        }
    }
}
</script>

<style>
.square-btn-icon {
    min-width: 20px !important;
    padding: 8px !important;
}
</style>